export const decompressSpec = (input: string): object => {
  // Replace keys with the proper JSON key names
  let formattedString = input
    .replace(/n:/g, '"Name":')
    .replace(/r:/g, '"Region":')
    .replace(/a:/g, '"Attributes":')
    .replace(/k:/g, '"Key":')
    .replace(/v:/g, '"Value":');
  
  // Add quotes around values (excluding numbers, booleans, and already quoted values)
  formattedString = formattedString.replace(/:([\w\s./-]+)([},\]])/g, (match, p1, p2) => {
    // If the value is not already quoted and is not a number/boolean, add quotes
    if (!/^".*"$/.test(p1) && isNaN(Number(p1)) && p1 !== "true" && p1 !== "false") {
      return `:"${p1.trim()}"${p2}`;
    }
    return match;
  });

  // Parse the formatted string into JSON
  try {
    return JSON.parse(formattedString);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    throw error;
  }
}
