import { Table } from "@spheron/ui-library";
import React from "react";
import Skeleton from "react-loading-skeleton";

interface IProps {
  headers: string[];
  rows: number;
}

const TableLoader = ({ headers, rows }: IProps) => {
  return (
    <Table
      classname="leaderboard-table"
      tableHeader={{
        tableRowType: "record",
        tableRow: headers.map((header: string, i) => ({
          id: i,
          title: header,
          tableCellType: "header",
        })),
      }}
      tableRows={Array.from(Array(rows)).map((i: number) => ({
        id: i,
        tableRowType: "record",
        tableRow: headers.map(() => ({
          id: 1,
          content: (
            <div className="py-6 w-full">
              <Skeleton height={25} width="80%" duration={2} />
            </div>
          ),
          tableCellType: "default",
        })),
      }))}
    />
  );
};

export default TableLoader;
