import { ethers, JsonRpcProvider } from "ethers";
import { SAMPLE_PROVIDER_ADDRESS, SPHERON_RPC_URL } from "@/config";
import ProviderRegistryAbi from "@/contracts/abis/ProviderRegistry.json";
import ProviderAttributeRegistryAbi from "@/contracts/abis/ProviderAttributeRegistry.json";
import {
  ProviderRegistry,
  ProviderAttributeRegistry,
} from "@/contracts/addresses";
import { notFound } from "next/navigation";
import { decompressSpec } from "@/utils/spec";

export interface ProviderDetails {
  name: string;
  region: string;
  spec: string;
  hostUri: string;
  certificate: string;
  paymentsAccepted: string[];
  status: string;
  trust: number;
  timestamp: number;
  rewardWallet: string;
}

export const getProviderDetails = async (
  providerAddress: string
): Promise<ProviderDetails> => {
  try {
    const provider = new JsonRpcProvider(SPHERON_RPC_URL);
    const contractAbi = ProviderRegistryAbi;
    const contractAddress = ProviderRegistry;

    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider
    );
    const response = await contract.getProviderByAddress(providerAddress);

    let name, region;
    try {
      const { Name, Region } = JSON.parse(response.spec);
      name = Name;
      region = Region;
    } catch {
      try {
        const { Name, Region } = decompressSpec(response.spec) as any;
        name = Name;
        region = Region;
      } catch {
        name = "";
        region = "";
      }
    }

    const providerDetailsData = {
      name,
      region,
      spec: response.spec,
      hostUri: response.hostUri,
      certificate: response.certificate,
      paymentsAccepted: response.paymentsAccepted,
      status: response.status.toString(),
      trust: Number(response.tier.toString()) + 1,
      timestamp: Number(response.joinTimestamp.toString()),
      rewardWallet: response.rewardWallet,
    };

    return providerDetailsData;
  } catch (error) {
    console.log("Error in getting provider details -> ", error);
    notFound();
  }
};

export const getProviderAttrs = async (
  providerAddress: string,
  category: string
) => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = ProviderAttributeRegistryAbi;
  const contractAddress = ProviderAttributeRegistry;

  const contract = new ethers.Contract(contractAddress, contractAbi, provider);
  const response = await contract.getAttributes(providerAddress, category);

  console.log("attr ids -> ", response);

  return response;
};

export const getProviderPendingAttributes = async (
  providerAddress: string,
  category: string
) => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = ProviderAttributeRegistryAbi;
  const contractAddress = ProviderAttributeRegistry;

  const contract = new ethers.Contract(contractAddress, contractAbi, provider);
  const response = await contract.getPendingAttributes(
    providerAddress,
    category
  );

  console.log("pending attrs ids -> ", response);

  return response;
};
