import { BASE_URL } from "@/config";

export const getProviderVersion = async (hostUri: string) => {
  try {
    const versionResponse = await fetch(
      `${BASE_URL}/api/version?hostUri=${encodeURIComponent(hostUri)}`,
      { method: "GET", cache: "no-store", signal: AbortSignal.timeout(10000) }
    );
    const versionResponseJson = await versionResponse.json();

    console.log("provider version -> ", versionResponseJson);

    if (versionResponseJson.spheron) {
      return versionResponseJson.spheron.version;
    } else {
      return versionResponseJson.akash.version;
    }
  } catch (error) {
    console.log("error in get provider version -> ", error);
    return "-";
  }
};
