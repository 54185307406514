import { ethers, JsonRpcProvider } from "ethers";
import { SAMPLE_PROVIDER_ADDRESS, SPHERON_RPC_URL } from "@/config";
import ProviderRegistryAbi from "@/contracts/abis/ProviderRegistry.json";
import { ProviderRegistry } from "@/contracts/addresses";
import { notFound } from "next/navigation";
import { getProviderStatus, StatusResponse } from "./status";
import { getProviderRewardsPerEra, getTotalRewards } from "./rewards";
import { TrustTierMultiplier } from "@/utils";
import { getProviderVersion } from "./version";
import { decompressSpec } from "@/utils/spec";

export const getAllProviders = async (
  pageSize: number = 10,
  pageNumber: number = 1,
  searchTerm: string = ""
) => {
  try {
    const provider = new JsonRpcProvider(SPHERON_RPC_URL);
    const contractAbi = ProviderRegistryAbi;
    const contractAddress = ProviderRegistry;

    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider
    );
    const responses: any[] = await contract.getAllProviders();

    // Filter out providers with localhost hostUri or no name
    const filteredResponses = responses.filter((response) =>
      // response[6] !== "localhost" && response[1] && response[1].trim() !== ""
      {
        if (response.hostUri !== "localhost") return true;
      }
    );

    // Apply search filter
    // const searchFilteredResponses = filteredResponses.filter(response =>
    //   response[1].toLowerCase().includes(searchTerm.toLowerCase()) || // name
    //   response[3].toLowerCase().includes(searchTerm.toLowerCase())    // address
    // );

    // Process all providers to get their details including total rewards
    const allProviderDetails = await Promise.all(
      filteredResponses.map(async (response) => {
        let name, region;
        try {
          const { Name, Region } = JSON.parse(response.spec);
          name = Name;
          region = Region;
        } catch {
          try {
            const { Name, Region } = decompressSpec(response.spec) as any;
            name = Name;
            region = Region;
          } catch {
            name = "";
            region = "";
          }
        }
        let specs: StatusResponse = {
          totalCPUs: 0,
          totalMemory: 0,
          totalStorage: 0,
          gpuInfos: [],
        };
        let version = "-";
        if (response.status.toString() === "2") {
          specs = await getProviderStatus(response.hostUri);
          version = await getProviderVersion(response.hostUri);
        }
        const isRegistered = Number(response.status) === 1;
        const perEraRewardData = await getProviderRewardsPerEra(
          response.walletAddress,
          isRegistered,
          TrustTierMultiplier[Number(response.tier.toString()) + 1]
        );
        const totalRewardsData = await getTotalRewards(
          response.walletAddress,
          isRegistered,
          perEraRewardData
        );
        return {
          id: response.providerId.toString(),
          name,
          region,
          address: response.walletAddress,
          hostUri: response.hostUri,
          status: response.status.toString(),
          trust: Number(response.tier.toString()) + 1,
          specs,
          perEraRewardData,
          totalRewardsData,
          timestamp: Number(response.joinTimestamp.toString()),
          version,
        };
      })
    );

    const filteredProviders = [...allProviderDetails].filter(
      (provider) => provider.name.trim() !== "" && provider.region !== "dev-spheron"
    );

    // Sort providers by status, total rewards (assuming totalRewardsData is a number)
    const sortedProviders = [...filteredProviders].sort((a, b) => {
      if (a.status === "3" && b.status !== "3") return 1;
      if (a.status !== "3" && b.status === "3") return -1;

      const totalRewardsDataDiff =
        Number(b.totalRewardsData.toString()) -
        Number(a.totalRewardsData.toString());

      // If totalRewardsData is the same, compare by perEraRewards
      if (totalRewardsDataDiff === 0) {
        return (
          Number(b.perEraRewardData.toString()) -
          Number(a.perEraRewardData.toString())
        );
      }

      return totalRewardsDataDiff;
    });

    const sortedProvidersWithRank = [...sortedProviders].map((item, index) => ({
      ...item,
      rank: index + 1,
    }));

    // Calculate total pages after filtering and sorting
    const totalFilteredCount = sortedProviders.length;
    // const totalPages = Math.ceil(totalFilteredCount / pageSize);

    // Calculate start and end indices for pagination
    // const startIndex = (pageNumber - 1) * pageSize;
    // const endIndex = startIndex + pageSize;

    // Slice the sorted providers array to get the current page's data
    // const paginatedProviders = sortedProviders.slice(startIndex, endIndex);

    return {
      providers: sortedProvidersWithRank,
      totalCount: totalFilteredCount,
      // pageSize,
      // pageNumber,
      // totalPages,
    };
  } catch (error) {
    console.log("Error in getting provider details -> ", error);
    notFound();
  }
};
