import Skeleton from "react-loading-skeleton";

interface IProps {
  podiumPlace: number;
}

const LeaderboardCardLoader = ({ podiumPlace }: IProps) => {
  return (
    <div
      className={`py-3 px-4 flex flex-row items-center gap-x-4 bg-base-fg dark:bg-dark-base-fg rounded-xl h-max
    ${podiumPlace === 1 ? "pt-[18px]" : ""}`}
    >
      <div className="flex flex-col justify-center items-center gap-0.5">
        <Skeleton height={48} width={48} className="rounded-full" />
      </div>
      <div className="flex flex-col gap-1">
        <Skeleton height={28} width={150} />
        <div className="flex flex-row gap-2">
          <Skeleton height={20} width={58} />

          <Skeleton height={20} width={155} />
        </div>
      </div>
    </div>
  );
};

export default LeaderboardCardLoader;
