import { BASE_URL } from "@/config";

export interface GPUInfo {
  vendor: string;
  name: string;
  num: number;
  availableNum: number;
}
export interface StatusResponse {
  totalCPUs: number;
  totalMemory: number;
  totalStorage: number;
  gpuInfos: GPUInfo[];
}

export const getAllocatableGPUs = (data: any): GPUInfo[] => {
  const nodes = data.cluster.inventory.available.nodes;
  let gpuMap: Map<string, GPUInfo> = new Map();

  nodes.forEach((node: any) => {
    const gpuInfos = node.allocatable.gpu_infos;

    if (gpuInfos && gpuInfos.length > 0) {
      const availableGPUPerType = node.available.gpu / gpuInfos.length;

      gpuInfos.forEach((gpu: any) => {
        const key = `${gpu.vendor}|${gpu.name}`;

        if (gpuMap.has(key)) {
          const existingGPUInfo = gpuMap.get(key)!;
          existingGPUInfo.num++;
          existingGPUInfo.availableNum += availableGPUPerType;
        } else {
          gpuMap.set(key, {
            vendor: gpu.vendor,
            name: gpu.name,
            num: 1,
            availableNum: availableGPUPerType,
          });
        }
      });
    }
  });

  return Array.from(gpuMap.values()).map(gpuInfo => ({
    ...gpuInfo,
    availableNum: Math.floor(gpuInfo.availableNum) // Ensure availableNum is an integer
  }));
};

export const getProviderStatus = async (hostUri: string) => {
  try {
    const statusResponse = await fetch(
      `${BASE_URL}/api/status?hostUri=${encodeURIComponent(hostUri)}`,
      { method: "GET", cache: "no-store", signal: AbortSignal.timeout(20000) }
    );
    const statusResponseJson = await statusResponse.json();

    // console.log(
    //   "status response -> ",
    //   JSON.stringify(statusResponseJson, null, 2)
    // );

    let totalMilliCPUs = 0; // in millicores
    let totalMemoryBytes = 0; // in bytes
    let totalStorageBytes = 0; // in bytes
    let totalCPUs = 0;
    let totalMemory = 0;
    let totalStorage = 0;
    let totalGpus = 0;
    let availableMilliCPUs = 0; // in millicores
    let availableMemoryBytes = 0; // in bytes
    let availableStorageBytes = 0; // in bytes
    let availableCPUs = 0;
    let availableMemory = 0;
    let availableStorage = 0;
    let availableGpus = 0;
    let gpuInfos: GPUInfo[] = [];

    if (!statusResponseJson.error) {
      const nodes = statusResponseJson.cluster.inventory.available.nodes;
      nodes.forEach((node: any) => {
        totalMilliCPUs += node.allocatable.cpu;
        totalMemoryBytes += node.allocatable.memory;
        totalStorageBytes += node.allocatable.storage_ephemeral;
        totalGpus += node.allocatable.gpu;
        availableMilliCPUs += node.available.cpu;
        availableMemoryBytes += node.available.memory;
        availableStorageBytes += node.available.storage_ephemeral;
        availableGpus += node.available.gpu;
      });
      totalCPUs = totalMilliCPUs / 1000;
      totalMemory = Number((totalMemoryBytes / 10 ** 9).toFixed(2));
      totalStorage = Number((totalStorageBytes / 10 ** 9).toFixed(2));
      availableCPUs = Number((availableMilliCPUs / 1000).toFixed(2));
      availableMemory = Number((availableMemoryBytes / 10 ** 9).toFixed(2));
      availableStorage = Number((availableStorageBytes / 10 ** 9).toFixed(2));

      gpuInfos = getAllocatableGPUs(statusResponseJson);
    }
    const result = {
      totalCPUs,
      totalMemory,
      totalStorage,
      totalGpus,
      availableCPUs,
      availableMemory,
      availableStorage,
      availableGpus,
      gpuInfos,
    };

    console.log("Total Resources -> ", result);

    return result;
  } catch (error) {
    console.log("error in get provider status -> ", error);
    return {
      totalCPUs: 0,
      totalMemory: 0,
      totalStorage: 0,
      totalGpus: 0,
      availableCPUs: 0,
      availableMemory: 0,
      availableStorage: 0,
      availableGpus: 0,
      gpuInfos: [],
    };
  }
};
