import { Badge, Status } from "@spheron/ui-library";
import VerifiedIcon from "@spheron/ui-library/dist/assets/verified.svg";
import WarningIcon from "@spheron/ui-library/dist/assets/error.svg";
import CrownIcon from "@/assets/crown-icon.svg";
import { getProviderStatusFromStatusId } from "@/utils";
import dayjs from "dayjs";
import { cloneElement } from "react";

interface IProps {
  titleText: string;
  isVerified: boolean;
  icon: JSX.Element;
  podiumPlace: number;
  status: string;
  timestamp: number;
}

const LeaderboardCard = ({
  titleText,
  isVerified,
  podiumPlace,
  icon,
  status,
  timestamp,
}: IProps) => {
  const getBorderColor = () => {
    switch (podiumPlace) {
      case 1:
        return "border-[#FFAA00]";
      case 2:
        return "border-[#01ABFF]";
      case 3:
        return "border-[#36B37E]";
      default:
        return "";
    }
  };

  const getBgColor = () => {
    switch (podiumPlace) {
      case 1:
        return "bg-[#FFAA00]";
      case 2:
        return "bg-[#01ABFF]";
      case 3:
        return "bg-[#36B37E]";
      default:
        return "";
    }
  };

  const iconWithClassname = cloneElement(icon, {
    className: `border-[3px] rounded-full ${getBorderColor()}`,
    style: { height: "48px", width: "48px" },
  });

  return (
    <div className="py-3 px-4 flex flex-row items-center gap-x-4 bg-base-fg dark:bg-dark-base-fg rounded-xl h-max">
      <div className="flex flex-col justify-center items-center gap-0.5">
        {podiumPlace === 1 && <CrownIcon className="w-5 h-4" />}
        {iconWithClassname}
        <div
          className={`relative w-[14px] h-[14px] rounded-sm -mt-3 ${getBgColor()} transform rotate-45`}
        >
          <div className="absolute w-full flex items-center justify-center transform -rotate-45">
            <span className="text-white text-[10px] leading-2 font-600 flex items-center justify-center">
              {podiumPlace}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex flex-row gap-2">
          <span className="text-base-heading-text-color dark:text-dark-base-heading-text-color">
            {titleText}
          </span>
          <Badge
            badgeType={isVerified ? "info" : "warning"}
            solid={false}
            size="medium"
            leftIcon={
              isVerified ? (
                <VerifiedIcon className="w-5 h-5" />
              ) : (
                <WarningIcon className="w-5 h-5" />
              )
            }
            text={isVerified ? "Verified" : "Unverified"}
          />
        </div>
        <div className="flex flex-row gap-2">
          <Status
            state={getProviderStatusFromStatusId(Number(status))}
            tooltipText={`This provider is in ${getProviderStatusFromStatusId(
              Number(status)
            )} state`}
          />
          <p className="text-base-sub-text-color dark:text-dark-base-sub-text-color text-sm font-normal">
            <span className="hidden lg:inline pr-1">|</span> Joined at{" "}
            {dayjs(timestamp * 1000).format("MMM DD YYYY")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeaderboardCard;
