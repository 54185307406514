import { ethers, JsonRpcProvider } from "ethers";
import ResourceRegistryAbi from "@/contracts/abis/ResourceRegistry.json";
import { SAMPLE_PROVIDER_ADDRESS, SPHERON_RPC_URL } from "@/config";
import {
  ResourceRegistryCPU,
  ResourceRegistryGPU,
} from "@/contracts/addresses";

export const getResource = async (
  resourceId: string,
  category: string = "GPU"
) => {
  const provider = new JsonRpcProvider(SPHERON_RPC_URL);
  const contractAbi = ResourceRegistryAbi;
  const contractAddress =
    category === "CPU" ? ResourceRegistryCPU : ResourceRegistryGPU;

  const contract = new ethers.Contract(contractAddress, contractAbi, provider);
  const response = await contract.getResource(resourceId);

  console.log("resource -> ", response);

  return response;
};
